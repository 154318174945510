// @flow

import React, { useState } from 'react';
import PapaParse from 'papaparse';
import Dropzone from 'react-dropzone';
import { Heading, Box, Text as Line, Button} from 'rebass';
import './App.css';
import styled from 'styled-components';
import NumbersLettersPDF from './NumbersLettersPDF.js';
import CodesPDF from './CodesPDF.js';

const Upload = styled(Button)`
  border: 2px solid #707070;
  background: none;
  color: black;
  cursor: pointer;
  border-radius: 1em;
`

const readUploadedFileAsText = inputFile => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsText(inputFile, "iso-8859-1");
    });
};

const chunk = (rows) => {
    const perChunk = 36;
    return rows.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, [])
}

const isFirstLetterAnInteger = (rows) => Number.isInteger(+rows[0][0][0]);


const App  = () => {
    const [files, setFiles] = useState([]);
    const [index, setIndex] = useState(0);

	  return (
		    <div className="App">
			      <Box className="Top">
			          <Box className="Menu">
			              <a href="https://skienpensjon.no/">
			                  <img src="/logo.svg" alt="Logo"/>
			              </a>
			          </Box>
			      </Box>

			      <Box className="upload" m={"auto"}>
			          <Heading my={4}>Lag etikett</Heading>

			          <Dropzone  className="uploadBox" onDrop={(acceptedFiles) => {
					          const newFiles = [];
					          Promise.all(acceptedFiles.map(async (file) => {
                        const fileContents = await readUploadedFileAsText(file);
                        const { data } = PapaParse.parse(fileContents);
                        chunk(data).map( page =>  newFiles.push({ file, rows : page}))
					          })).then(() => {
                        setFiles(newFiles);
                    });

			          }}>
			              <Line fontSize={[ 3 ]}>Dra inn fil her</Line>
			              <Line className="light" fontSize={[ 2 ]}>Bruk CSV filer.</Line>
			              <Line my={3} fontSize={[ 2 ]} fontWeight="light" >Eller</Line>
			              <Line>
			                  <Upload className="button">Last opp</Upload>
			              </Line>
			          </Dropzone>

                <Line my={4}  className="light" fontSize={[ 2 ]}>
                    Etikettappen genererer csv filer til pdf'er du kan skrive ut som etiketter. <br />
                    Appen genererer vanlige og korrigerende etiketter.
                </Line>

			      </Box>
			      {files.map(({file, rows, show}, i) => (
			      <Box className="center" key={i} my={2} mx="auto">
                <Box className="center">
                    {isFirstLetterAnInteger(rows)
                    ? (<NumbersLettersPDF done={() => {
                        setIndex(i + 1);
                    }} show={i <= index } index={i} file={file} rows={rows}></NumbersLettersPDF>)
                    : <CodesPDF how={file.show} file={file} rows={rows}></CodesPDF>}
					      </Box>

			      </Box>))
			      }

			  </div>
    );
};

export default App;
