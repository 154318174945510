
import { StyleSheet } from '@react-pdf/renderer';


export const colours = {
	  "0" : "#FFFF00",
	  "1" : "#02b1ce",
	  "2" : "#FF312C",
	  "3" : "#6cc24a",
	  "4" : "#776e64",
	  "5" : "#c1a01e",
	  "6" : "#007041",
	  "7" : "#034190",
	  "8" : "#fd8600",
	  "9" : "#000000",
	  "U" : "#FFFF00",
	  "V" : "#FF312C",
	  "M" : "#034190",
	  "A" : "#776e64",
	  "B" : "#6cc24a",
	  "E" : "#007041",
	  "R" : "#7d4800"
};

export const styles = StyleSheet.create({
    page: {
		    paddingTop: "13mm",
		    paddingBottom: "14mm",
		    paddingRight: "5mm",
		    paddingLeft: "4mm",
		    fontFamily: "Oswald"
    },
    lettersPage: {
		    paddingTop: "13mm",
		    paddingBottom: "13mm",
		    paddingRight: "5mm",
		    paddingLeft: "5mm",
		    fontFamily: "Oswald"
    },
	  sticker: {
		    height: "3cm"
	  },
	  name: {
		    position: "relative",
		    flexDirection: 'row-reverse',
		    lineHeight: 1.2,
		    marginRight: "20mm",
		    fontSize: "5mm",
        top: "-1px"
	  },
	  flip: {
		    position: "relative",
		    lineHeight: 1.2,
		    flexDirection: 'row',
		    marginRight: "20mm",
		    fontSize: "5mm",
		    transform: "rotate(180deg)",
        bottom: "-1px"
	  },
    row: {
		    flexDirection: 'row-reverse',
        marginRight: "5mm"
    },
    smallRow: {
		    flexDirection: 'row-reverse',
        marginRight: "2mm",
        paddingTop: "6mm",
    },
	  nameText: {
		    marginRight: "2cm"
    },

	  digits: {
		    lineHeight: 1.6,
		    fontSize: "12mm",
		    position: "relative",
		    display: "block",
		    width: '17mm',
		    textAlign: 'center'
	  },
    stickerHop: {
		    marginLeft: "10mm",
	  },
	  number: {
		    marginRight: "4mm",
		    marginLeft: "4mm",
		    transform: "rotate(90deg)",
	  },

	  letter: {
		    marginRight:"3mm",
		    marginTop: "3mm",
		    padding: "0",
        display: "block",
        lineHeight: "13mm",
		    height: '13mm',
        width: '13mm',
		    transform: "rotate(90deg)",
	  },
	  char: {
		    lineHeight: 1.6,
		    fontSize: "8mm",
		    position: "relative",
		    display: "block",

		    width: '13mm',
		    textAlign: 'center'
	  },
	  decor: {

	  },
	  numberText: {

	  }
});
