// @flow

import React from 'react';
import { useState } from 'react';
import { Text as Line} from 'rebass';

import styled from 'styled-components';

import { Document, Page, Text, View, PDFDownloadLink, Font } from '@react-pdf/renderer';
import { colours, styles } from './style';



Font.register(
    `//${window.location.host}/fonts/Oswald-Light.ttf`,
    { family: 'Oswald' },
);

Font.register(
    `//${window.location.host}/fonts/Oswald-Bold.ttf`,
    { family: 'OswaldBold' },
);

const PDF = styled.iframe`
	  height: 100vh;
	  width: 85vh;
    frameborder: 0;
    background: white;
`

const Link = styled(Line)`
	  border-bottom: 3px solid #FAE049;
    color: #000;
    font-size: 34px;
    text-decoration: none;
`

const NumberRow = (digits:String): Array<Text> => {
	  const blocks = [];

	  for (let i = 0; i < 6; i++) {
		    blocks.push(
			      <View style={colour( digits[i], styles.number) }  key={i} >
			 	        <Text style={styles.digits}>
					          <Text style={underline(digits[i])}>{digits[i]}</Text> <Text style={underline(digits[i])}>{digits[i]}</Text>
				        </Text>
			      </View>
			  )
    }
	  return blocks;
}

const Letter = ({letter = ""}): View => (
	  <View style={styles.letter} >
	      <Text style={colour( letter, styles.char)} >
	 	        <Text style={underline(letter)}>{String(letter || "").toUpperCase()}</Text> <Text style={underline(letter)}>{String(letter || "").toUpperCase()}</Text>
	      </Text>
	  </View>
)

const underline = (char:String|Number) => ({
		textDecoration: (String(char) === String(9) || String(char) === String(6))? "underline" : "none"
})

const colour = (char:String|Number, obj) => ({
	  ...obj,
	  backgroundColor: colours[String(char || "").toUpperCase()] || "white",
	  color: "white"
})

const Stickers = ({file, rows}) => (
	  <Document>
		    <Page size="A4" style={styles.page}>
			      {rows.map((row, index) => (
				        <View style={styles.sticker} key={index} >
					          <View style={styles.name}>
						            <Text>{row[0]}</Text><Text style={styles.nameText}>{row[1]}</Text>
					          </View>
					          <View style={styles.row}>
						            {!!row[0] && NumberRow(row[0])}
						            <Letter letter={row[2]}></Letter>
						            <Letter letter={row[3]}></Letter>
					          </View>
					          <View style={styles.flip}>
						            <Text style={styles.nameText}>{row[0]}</Text><Text>{row[1]}</Text>
					          </View>
				        </View>
			      ))}
		    </Page>
	  </Document>
);

const NumbersLettersPDF  = ({file, rows, show, index, done}) => {
    const [pdfURL, setURL] = useState(false);

    if (!show) {
        return null;
    }


    return  (<PDFDownloadLink document={(<Stickers file={file} rows={rows}></Stickers>)}>
        {({ url, loading }) => {

             if (!loading && !pdfURL) {
                 setURL(url);
                 done();
                 return null;
             }

             if (pdfURL) {
                 return (<>
                     <Link color="black" my={4} fontSize={[ 2 ]} fontWeight="bold" >
								         {index + 1}. Last ned {file.name.replace(/\..*$/gi, "_" + (index + 1) + "_.pdf")}
						         </Link>
                     {!(navigator.userAgent.indexOf('MSIE')!==-1
                      || navigator.appVersion.indexOf('Trident/') > -1) && (<PDF src={url} />)}
                 </>);
             } 
        }}
    </PDFDownloadLink>);



};

export default NumbersLettersPDF;
