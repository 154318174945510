// @flow

import React from 'react';
import { Text as Line} from 'rebass';

import styled from 'styled-components';

import { Document, Page, Text, View, PDFDownloadLink, Font } from '@react-pdf/renderer';
import { colours, styles } from './style';


Font.register(
    `//${window.location.host}/fonts/Oswald-Light.ttf`,
    { family: 'Oswald' },
);

Font.register(
    `//${window.location.host}/fonts/Oswald-Bold.ttf`,
    { family: 'OswaldBold' },
);

const PDF = styled.iframe`
	  height: 100vh;
	  width: 85vh;
    frameborder: 0;
    background: white;
`


const Letter = ({letter = ""}): View => (
	  <View style={styles.letter} >
	      <Text style={colour( letter, styles.char)} >
	 	        <Text style={underline(letter)}>{String(letter || "").toUpperCase()}</Text> <Text style={underline(letter)}>{String(letter || "").toUpperCase()}</Text>
	      </Text>
	  </View>
)

const underline = (char:String|Number) => ({
		textDecoration: (String(char) === String(9) || String(char) === String(6))? "underline" : "none"
})

const colour = (char:String|Number, obj) => ({
	  ...obj,
	  backgroundColor: colours[String(char || "").toUpperCase()] || "white",
	  color: "white"
})


const Stickers = ({file, rows}) => (
	  <Document>
		    <Page size="A4" style={styles.page}>

			      {rows.map((row, index) => (
                <View style={styles.sticker} >
                    <View style={styles.smallRow} key={index}>
                        {
                            row.map((cell, cellIndex) => (
                                <View key={cellIndex} style={((cellIndex  + 1)%3) === 0 ? styles.stickerHop : {}}>
						                        <Letter letter={cell}></Letter>
                                </View>
			                      ))
                        }
                    </View>
                </View>
            )
            )}

		    </Page>
	  </Document>
);


const CodesPDF  = ({file, rows}) => {
	  return (
		    <PDFDownloadLink document={(<Stickers file={file} rows={rows}></Stickers>)}>
            {({ blob, url, loading, error }) => !loading && (
                 <>
                     <Line color="black" my={2} fontSize={[ 2 ]} fontWeight="bold" >
									       Last ned {file.name.replace(/\..*$/gi, ".pdf")}
							       </Line>
                     {!(navigator.userAgent.indexOf('MSIE')!==-1
                      || navigator.appVersion.indexOf('Trident/') > -1) && (<PDF src={url} />)}
                 </>
            )}
        </PDFDownloadLink>
	  );
};

export default CodesPDF;
